<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <div class="me-4 d-flex">
              <label class="col-form-label me-2">Budget Year</label>
              <div style="width: 150px">
                <v-select label="name" v-model="year" :options="years" :clearable="false" :reduce="(name) => name.id"
                  @option:selected="getPlansByYear($event)">
                </v-select>
              </div>
            </div>
            <label class="col-form-label me-2">Month</label>
            <div style="width: 200px">
              <v-select label="name" v-model="month" :options="months" :clearable="false" :reduce="(name) => name.id"
                :selectable="(options) => isSelectableMonth(options)"
                @option:selected="getPlansByMonth($event, yearId)">
              </v-select>
            </div>
            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Enrollment Approval</h4>
          <div class="mb-3">
            <a @click="clickApproved" class="badge me-2 cursor-pointerCSS"
              :class="{ 'badge-soft-primary': isApprovedButton }">
              Approved by me
            </a>
            <a @click="showRequestData" class="badge me-2 cursor-pointerCSS"
              :class="{ 'badge-soft-primary': !isApprovedButton }">
              Requested by me
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-if="!loading">
              <table class="table table-striped dt-responsive w-100 mb-3" id="enroll-approval-table" v-if="!loading">
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th class="hideColumn">Budget Year</th>
                    <th class="hideColumn">Month</th>
                    <th>Plan Name</th>
                    <th>Module</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Enroll User</th>
                    <th>Service Year</th>
                    <th>Requested By</th>
                    <th>Approved By</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(enrollList, index) in enrollLists" :key="index">
                    <td>{{ ++index }}</td>
                    <td class="hideColumn">{{ enrollList.monthly_plan.budget_year_id }}</td>
                    <td class="hideColumn">{{ enrollList.monthly_plan.month }}</td>
                    <td>{{ enrollList.monthly_plan.name }}</td>
                    <td>{{ enrollList.module.name }}</td>
                    <td>{{ enrollList.monthly_plan.from_date }}</td>
                    <td>{{ enrollList.monthly_plan.to_date }}</td>
                    <td>{{ enrollList.enrolled_by.name }}</td>
                    <td>{{ enrollList.service_year + ' year' }}</td>
                    <td>{{ enrollList.requested_by.name }}</td>
                    <td>{{ enrollList.approved_by.name }}</td>
                    <td>
                      <badgeDanger v-if="enrollList.is_enroll_confirmed == 0" name="Rejected" />
                      <badgeSuccess v-else-if="enrollList.is_enroll_confirmed == 1" name="Approved" />
                      <badgeWarning v-else-if="enrollList.is_enroll_confirmed == 2" name="Waiting" />
                      <badgeCanceled v-else-if="enrollList.is_enroll_confirmed == 3" name="Canceled" />
                    </td>
                    <td>
                      <span>
                        <router-link class="action-icon" :to="{
                          name: 'enrollment-approval-update',
                          params: { id: enrollList.id },
                        }">
                          <i class="mdi mdi-square-edit-outline"></i>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import moment from "moment";
import badgeSuccess from "../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../components/shared/badgeDanger.vue";
import badgeWarning from "../../components/shared/badgeWarning.vue";
import badgeCanceled from "../../components/shared/badgeCanceled.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    badgeSuccess,
    badgeDanger,
    badgeWarning,
    badgeCanceled,
  },
  data() {
    return {
      enrollLists: [],
      enrollData: [],
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      employeeLists: [],
      allMonthlyPlans: [],
      current_budgetyear_id: "",
      current_year_month: "",
      current_year_month_day: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      enrollList: null,
      isApprovedButton: true,
      // isRequestedButton: false,
    };
  },
  methods: {
    async getAllEmployee() {
      this.loading = true;
      await axios.get(`${this.baseUrlHRIS}api/employees`).then((response) => {
        this.employeeLists = response.data.data;
        this.loading = false;
      });
    },
    isSelectableMonth(options) {
      if(!this.month) return true;
      return this.month != options.id;
    },
    clickApproved() {
      this.getAllMonthlyPlans();
      this.getCurrentBudgetYear();
      this.current_year_month = moment().format("YYYY-MM");
      this.getAllEnrollLists();
      this.isApprovedButton = true;
    },
    showRequestData() {
      this.getAllMonthlyPlans();
      this.getCurrentBudgetYear();
      this.current_year_month = moment().format("YYYY-MM");
      this.getRequestedEnrollLists();
      this.isApprovedButton = false;
    },
    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.enrollLists = "";
      this.month = "";

      await this.getAllMonths(this.yearId);

      // this.enrollLists = this.enrollData.filter((item) => {
      //   if (item.monthly_plan.budget_year_id == this.yearId) {
      //     return item;
      //   }
      // });
      // $("#enroll-approval-table").DataTable();

      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        console.log(settings, dataIndex);
        let filter = value.id;
        let stage = data[1]; // stage from datatable
        if (filter == stage) {
          return true;
        }
        return false;
      });
      $("#enroll-approval-table").DataTable().draw();
    },

    async getAllMonths(year_id) {
      // this.loading = true;
      // this.$Progress.start();
      axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            return { id: key, name: obj[key] };
          });
          // this.loading = false;
          // this.$Progress.finish();
        });
    },

    async getPlansByMonth(value, year_id) {
      // if (this.yearId) {
      this.enrollLists = "";

      // this.enrollData.filter((item) => {
      //   if (item.monthly_plan.budget_year_id == this.yearId) {
      //     this.months.filter((data) => {
      //       if (data.id == item.monthly_plan.month) {
      //         console.log(item);
      //         this.enrollLists = item;
      //       }
      //     });
      //   }
      // });
      // $("#enroll-approval-table").DataTable();

      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        console.log(settings, dataIndex);
        let yearFilter = year_id;
        let yearStage = data[1];
        let monthFilter = value.id;
        let monthStage = data[2]; // stage from datatable
        if (yearFilter == yearStage && monthFilter == monthStage) {
          return true;
        }
        return false;
      });
      $("#enroll-approval-table").DataTable().draw();
    },

    async getAllEnrollLists() {
      this.year = "";
      this.yearId = "";
      this.month = "";
      this.enrollLists = "";
      this.loading = true;
      this.$Progress.start();
      var current_user_id = this.$store.state.auth.user.employee_id;
      await axios
        .get(
          `${this.baseUrl}admin/v1/enrollment-approval?approvedby=${current_user_id}`
        )
        .then((response) => {
          // this.enrollLists = response.data.data;
          this.enrollData = response.data.data;
          
          this.enrollLists = this.enrollData;
          this.enrollLists.forEach((enroll) => {
            this.employeeLists.forEach((emp) => {
              if(enroll.enrolled_by.employee_id == emp.emp_id) {
                enroll.service_year = emp.service_year;
              }
            })
          })
          // this.enrollLists = this.enrollData.filter((item) => {
          //   if (item.monthly_plan.expired_date >= this.current_year_month_day) {
          //     return item;
          //   }
          // });

          this.loading = false;
          this.$Progress.finish();
        });
      $("#enroll-approval-table").DataTable();

      this.clearFilter();
    },

    async getRequestedEnrollLists() {
      this.year = "";
      this.yearId = "";
      this.month = "";
      this.enrollLists = "";
      this.loading = true;
      this.$Progress.start();
      var current_user_id = this.$store.state.auth.user.employee_id;
      await axios
        .get(
          `${this.baseUrl}admin/v1/enrollment-approval?requestedby=${current_user_id} & approvedby=${current_user_id}`
        )
        .then((response) => {
          // this.enrollLists = response.data.data;
          this.enrollData = response.data.data;

          this.enrollLists = this.enrollData;
          this.enrollLists.forEach((enroll) => {
            this.employeeLists.forEach((emp) => {
              if(enroll.enrolled_by.employee_id == emp.emp_id) {
                enroll.service_year = emp.service_year;
              }
            })
          })
          // this.enrollLists = this.enrollData.filter((item) => {
          //   if (item.monthly_plan.expired_date >= this.current_year_month_day) {
          //     return item;
          //   }
          // });

          this.loading = false;
          this.$Progress.finish();
        });
      $("#enroll-approval-table").DataTable();
      this.clearFilter();
    },

    async getAllMonthlyPlans() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/monthly-plans`)
        .then((response) => {
          this.allMonthlyPlans = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async getCurrentBudgetYear() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/latest-budgetyear`)
        .then((response) => {
          this.current_budgetyear_id = response.data.data.id;
          this.loading = false;
          this.$Progress.finish();
        });
    },

    refreshData() {
      this.year = "";
      this.yearId = "";
      this.month = "";
      this.enrollData = "";
      this.enrollLists = "";
      this.getBudgetYear();
      this.getAllMonthlyPlans();
      this.getCurrentBudgetYear();
      this.current_year_month = moment().format("YYYY-MM");
      this.current_year_month_day = moment().format("YYYY-MM-DD");
      this.getAllEnrollLists();
    },

    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#enroll-approval-table").DataTable().draw();
    },
  },
  async created() {
    this.clearFilter();
    if (this.$store.getters["odoo/getAllEmployees"].length > 0) {
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
    } else {
      await this.getAllEmployee();
    }
    this.getBudgetYear();
    this.getAllMonthlyPlans();
    this.getCurrentBudgetYear();
    this.current_year_month = moment().format("YYYY-MM");
    this.current_year_month_day = moment().format("YYYY-MM-DD");
    await this.getAllEnrollLists();
  },
};
</script>

<style>
.hideColumn {
  display: none !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
